import { NavTreeItem } from '@/lib/getters/getNav';
import { parseLinkField } from '@/lib/parsers/linkField';
import { normalizeLinkHref } from '@/lib/parsers/normalizeLinkHref';
import Link from 'next/link';
import React from 'react';
import { Button, NavButton } from '../Atoms/Button';
import { DownChevron, PhoneIcon, RightArrow } from '../Atoms/Icons';
import ExpandingBlock from '../utility/ExpandingBlock';
import { SearchBarMobile } from './SearchBarMobile';

interface MobileNavMenuProps {
  open: boolean;
  setIsMobileMenuOpen: (value: boolean) => void;
  phoneLink: ReturnType<typeof parseLinkField>;
  utilitiesNav: NavTreeItem[];
  primaryNav: NavTreeItem[];
  header: React.ReactNode;
}

const MobileNavMenuItem = ({
  item,
  closeNavMenu,
}: {
  item: NavTreeItem;
  closeNavMenu: () => void;
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <li>
      {item.children.length > 0 ? (
        <>
          <button
            onClick={() => setOpen(!open)}
            className="flex w-full items-center justify-between py-3">
            <h4>{item.title}</h4>
            <DownChevron
              className={`stroke-bgPrimary ml-2 text-lg ${open ? 'rotate-180 transform' : ''}`}
            />
          </button>
          <ExpandingBlock expanded={open}>
            <ul className="space-y-3 pt-3 font-medium">
              <li>
                <Link href={normalizeLinkHref(item.nodeUri) || '#'} onClick={closeNavMenu}>
                  <h6 className="inline-flex items-center text-m">
                    {item.title}&ensp;
                    <RightArrow className="stroke-bgPrimary" />
                  </h6>
                </Link>
              </li>
              {item.children.map((child) => (
                <li key={child.id}>
                  <Link href={normalizeLinkHref(child.nodeUri) || '#'} onClick={closeNavMenu}>
                    {child.title}
                  </Link>
                </li>
              ))}
            </ul>
          </ExpandingBlock>
        </>
      ) : (
        <div className="py-3">
          <Link href={normalizeLinkHref(item.nodeUri) || '#'}>
            <h4>{item.title}</h4>
          </Link>
        </div>
      )}
    </li>
  );
};

const MobileNavMenu = ({
  open,
  phoneLink,
  utilitiesNav,
  primaryNav,
  header,
  setIsMobileMenuOpen,
}: MobileNavMenuProps) => {
  const closeNavMenu = () => {
    setIsMobileMenuOpen(false);
  };
  return (
    <div
      className={`lg:hidden ${
        open ? 'block' : 'hidden'
      } bg-accent fixed top-0 z-50 h-full w-full overflow-auto`}>
      <div className="px-4 py-8">{header}</div>
      <ul className="text-tBase flex flex-col gap-6 px-4 pb-16">
        {primaryNav.map((item) => (
          <MobileNavMenuItem key={item.id} item={item} closeNavMenu={closeNavMenu} />
        ))}
        <SearchBarMobile closeNavMenu={closeNavMenu} />
        <li>
          <Button color={'midnightBlue'} href="/get-involved/donate/">
            Donate
          </Button>
        </li>
        {utilitiesNav.map((item) => (
          <li key={item.id}>
            <NavButton color={'midnightBlue'} href={normalizeLinkHref(item.nodeUri)}>
              {item.title}
            </NavButton>
          </li>
        ))}
        <li>
          <NavButton color={'midnightBlue'} href={'tel:' + phoneLink?.href}>
            <PhoneIcon className={`mr-2 ${'fill-white'}`} />
            {phoneLink?.children}
          </NavButton>
        </li>
      </ul>
    </div>
  );
};
export default MobileNavMenu;
