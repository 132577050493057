import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { Button } from '../Atoms/Button';
import { ContrastIcon, ReadModeIcon, TextSizeIcon } from '../Atoms/Icons';
import { useHighContrastMode, useLargeTextMode, useReadingMode } from './useAccessibilityActions';

const HighContrastMode = () => {
  const homepage = usePathname() === '/';
  const { value, toggle } = useHighContrastMode();

  useEffect(() => {
    document.documentElement.classList.toggle('theme-contrast', value);
  }, [value]);

  return (
    <Button
      color={homepage ? 'white' : 'midnightBlueOutline'}
      onClick={() => toggle(!value)}
      element="button"
      size="circleSmall">
      <ContrastIcon />
    </Button>
  );
};

const LargeTextMode = () => {
  const homepage = usePathname() === '/';
  const { value, toggle } = useLargeTextMode();

  useEffect(() => {
    document.documentElement.classList.toggle('theme-large-text', value);
  }, [value]);

  return (
    <Button
      color={homepage ? 'white' : 'midnightBlueOutline'}
      onClick={() => toggle(!value)}
      element="button"
      size="circleSmall">
      <TextSizeIcon />
    </Button>
  );
};

const ReadingMode = () => {
  const homepage = usePathname() === '/';
  const { value, toggle } = useReadingMode();

  return (
    <Button
      color={homepage ? 'white' : 'midnightBlueOutline'}
      onClick={() => toggle(!value)}
      element="button"
      size="circleSmall">
      <ReadModeIcon />
    </Button>
  );
};

const AccessibilityActions = () => {
  return (
    <li className="flex items-center justify-between gap-2">
      <HighContrastMode />
      <LargeTextMode />
      <ReadingMode />
    </li>
  );
};

export default AccessibilityActions;
